import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

export const absolute = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const Inside = styled.div`
  ${(props) => props.inset && "box-shadow:inset 0 0 6px rgba(0, 0, 0, 0.3);"}
  ${(props) =>
    props.outset && "box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);"}    
    padding:${({ padding = 5 }) => padding}px;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.overflow == false ? "" : "overflow:auto;scrollbar-width: thin;"}
`;
export const Outside = styled.div`
  box-sizing: border-box;
  flex: 1;
  ${(props) => !props.free && " height: 100%;overflow:auto;"}
  // width: 100%;
    padding:${({ padding = 5 }) => padding}px;
  overflow: auto;
`;

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => props.full && "height: 100%;"}
  flex-direction: column;
`;

export const Content = styled.div`
  box-sizing: border-box;
  flex: 1;
  // height: 100%;
  // overflow:auto;
`;

export const Full = styled.div`
  height: 100vh;

  body {
    margin: 0;

    ::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }
  }
`;

export const Parent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
export const Child = styled.div`
  overflow: auto;
  scrollbar-width: thin;
  ${absolute}
`;
export const ScrollContainer = ({
  children,
  styleParent,
  styleChild,
  styles = {},
}) => (
  <Parent style={styles?.parent ?? styleParent}>
    <Child className="scroll-child" style={styles?.child ?? styleChild}>
      {children}
    </Child>
  </Parent>
);

export default ({ children, ...other }) => (
  <Outside {...other}>
    <Inside {...other}>{children}</Inside>
  </Outside>
);
